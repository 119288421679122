import React, {useEffect, useRef, useState} from "react";
import Layout from "../../Layout/Layout";
import {useParams} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import Chart from "../../Chart/Chart";
import './CoinView.css';

let randomFactor = 25 + Math.random() * 25;

const CoinView: React.FC = () => {
    const {symbol} = useParams<{ symbol: string }>();
    useEffect(() => {

    }, []);
    return (
        <Layout>
            <Row className={"coin-page"}>
                <Col lg={12}>
                    <Chart symbol={symbol as string} interval={'4h'}/>
                </Col>
            </Row>
        </Layout>
    );
}

export default CoinView;