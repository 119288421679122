import React, {useEffect, useRef, useState} from 'react';
import './Indicators.css';
import Layout from './../../Layout'
import {useNavigate, useParams} from "react-router-dom";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import {Button, Col, Row, Form, Card, ListGroup} from "react-bootstrap";
import IndicatorForm from "./IndicatorForm";
import api from "../../Api";

type Indicator = {
    id: string;
    type: string;
    name: string;
    interval: string;
    weight: number;
    divergenceWeight: number;
    config: { [key: string]: any };
};

const SimulationIndicators: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [error, setError] = useState<string | null>(null);
    const [indicators, setIndicators] = useState<Indicator[]>([]);
    const [selectedIndicator, setSelectedIndicator] = useState<Indicator | null>(null);
    const [selectedType, setSelectedType] = useState<string>('ATR'); // По умолчанию - ATR
    const {getIndicators, removeIndicator} = api.useGetSimulation();

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedType(event.target.value);
        setSelectedIndicator(null); // При изменении типа сбрасываем редактируемый индикатор
    };

    const handleEditIndicator = (indicator: any) => {
        setSelectedType(indicator.type); // Устанавливаем тип выбранного индикатора
        setSelectedIndicator(indicator); // Устанавливаем индикатор для редактирования
    };

    const handleDeleteIndicator = async (indicator:Indicator) => {
        await removeIndicator(id as string, indicator.id);

        loadIndicators(id as string);
    }

    const loadIndicators = async (id:string) => {
        const response = await getIndicators(id);

        setIndicators(response.data.indicators);
    }

    const handleClearEdit = () => {
        setSelectedIndicator(null);
    }

    useEffect(() => {
        loadIndicators(id as string);
        setLoading(false);
    }, [id]);

    if (loading) {
        return <Layout><LoadingScreen/></Layout>;
    }

    if (error) {
        return <Layout>
            <div>{error}</div>
        </Layout>;
    }

    return <Layout>
        <Row>
            <Col lg={"9"}>
                <h3>Список существующих индикаторов</h3>
                {indicators.length > 0 ? (
                    <>
                        {indicators.map((indicator) => (
                            <Card key={indicator.id}>
                                <Card.Body>
                                    <Card.Title>{indicator.name}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{indicator.type}</Card.Subtitle>
                                    {indicator.type === 'ATR' && <>
                                        <Card.Text>
                                            <ListGroup>
                                                <ListGroup.Item><strong>Длина</strong>: {indicator.config.length}</ListGroup.Item>
                                            </ListGroup>
                                        </Card.Text>
                                    </>}
                                </Card.Body>
                                <Card.Footer>
                                    <Card.Link href="#" onClick={() => handleEditIndicator(indicator)}>Редактировать</Card.Link>
                                    <Card.Link className={"text-danger remove-link"} href="#" onClick={() => {handleDeleteIndicator(indicator)}}>Удалить</Card.Link>
                                </Card.Footer>
                            </Card>
                        ))}
                    </>
                ) : (
                    <p>Нет индикаторов</p>
                )}
            </Col>
            <Col lg={"3"}>
                <h3>{selectedIndicator ? "Редактировать индикатор" : "Добавить индикатор"}</h3>
                <Form.Group controlId="indicatorType">
                    <Form.Label column={"sm"}>Тип индикатора</Form.Label>
                    <Form.Select value={selectedType} onChange={handleTypeChange} disabled={!!selectedIndicator}>
                        <option value="ATR">ATR</option>
                        <option value="MACD">MACD</option>
                        <option value="RSI">RSI</option>
                        <option value="SMA">SMA</option>
                        <option value="Stochastic">Stochastic</option>
                        <option value="IchimokuCloud">IchimokuCloud</option>
                    </Form.Select>
                </Form.Group>

                <IndicatorForm indicator={selectedIndicator} type={selectedType} onSubmit={() => {}} simulationId={id as string} />
                {selectedIndicator && (
                    <Button variant="danger" onClick={handleClearEdit} className={"add-indicator"}>
                        Отменить
                    </Button>
                )}
            </Col>
        </Row>
    </Layout>
};

export default SimulationIndicators;