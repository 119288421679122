import React, {useEffect, useRef, useState} from 'react';
import {
    CandlestickData,
    createChart,
    CrosshairMode,
    IChartApi,
    ISeriesApi,
    SeriesMarker, SeriesMarkerPosition, SeriesMarkerShape, Time,
    UTCTimestamp
} from 'lightweight-charts';
import api from "../Api";

type TradingChartProps = {
    symbol: string;
    interval: string;
};

const TradingChart: React.FC<TradingChartProps> = ({ symbol, interval }) => {
    const chartContainerRef = useRef<HTMLDivElement | null>(null);
    const chartInstanceRef = useRef<IChartApi | null>(null);
    const seriesRef = useRef<ISeriesApi<'Candlestick'> | null>(null);
    const loadedRangeRef = useRef<number[]>([]);
    const loadingRef = useRef<boolean>(false);
    const [chartInterval, setChartInterval] = useState<string>(interval); // Локальный интервал для графика
    const volumeSeriesRef = useRef<ISeriesApi<'Histogram'> | null>(null);
    const zigzagSeriesRef = useRef<any>(null);
    const {getPivots} = api.useCoins();

    useEffect(() => {
        if (chartContainerRef.current) {
            // Создаем график
            const chart = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                },
                layout: {
                    textColor: '#d1d4dc',
                    background: { color: '#131722' },
                },
                grid: {
                    vertLines: { color: '#2B2B43' },
                    horzLines: { color: '#2B2B43' },
                },
                crosshair: { mode: CrosshairMode.Normal },
            });
            chartInstanceRef.current = chart;

            // Добавляем свечной график
            seriesRef.current = chart.addCandlestickSeries();

            seriesRef.current.priceScale().applyOptions({
                scaleMargins: {
                    // positioning the price scale for the area series
                    top: 0.1,
                    bottom: 0.4,
                },
            });

            // Добавляем серию для ZigZag
            zigzagSeriesRef.current = chart.addLineSeries({
                color: 'blue',
                lineWidth: 2,
            });

            // Добавляем гистограмму для объёмов
            volumeSeriesRef.current = chart.addHistogramSeries({
                color: '#26a69a',
                priceFormat: {
                    type: 'volume',
                },
                priceScaleId: '',
            });

            volumeSeriesRef.current.priceScale().applyOptions({
                // set the positioning of the volume series
                scaleMargins: {
                    top: 0.9, // highest point of the series will be 70% away from the top
                    bottom: 0,
                },
            });

            // Загружаем начальные данные
            loadData(symbol, chartInterval);


            // Обработчик для подгрузки данных при скролле
            chart.timeScale().subscribeVisibleTimeRangeChange((range) => {
                if (!range || !range.from || !loadedRangeRef.current.length || loadingRef.current) return;

                if (range.from as number <= loadedRangeRef.current[0]) {
                    loadData(symbol, interval, loadedRangeRef.current[0] - 60);
                }
            });

            return () => {
                chart.remove();
            };
        }
    }, [symbol, chartInterval]);

    const loadData = async (
        symbol: string,
        interval: string,
        endTime?: number
    ) => {
        try {
            loadingRef.current = true;

            const url = new URL('https://fapi.binance.com/fapi/v1/klines');
            url.searchParams.append('symbol', symbol);
            url.searchParams.append('interval', interval);
            url.searchParams.append('limit', '600');
            if (endTime) {
                url.searchParams.append('endTime', (endTime * 1000).toString());
            }

            const response = await fetch(url.toString());
            const data = await response.json();

            const formattedData = data.map((d: any) => ({
                time: d[0] / 1000, // Время в секундах
                open: parseFloat(d[1]),
                high: parseFloat(d[2]),
                low: parseFloat(d[3]),
                close: parseFloat(d[4]),
                volume: parseFloat(d[5]),
            }));

            // Устанавливаем данные для объёмов
            const volumeData = formattedData.map((item: any) => ({
                time: item.time,
                value: item.volume,
                color: item.close >= item.open ? '#26a69a' : '#ef5350', // Зелёный или красный
            }));



            if (endTime) {
                // Подгружаем данные в начало
                seriesRef.current?.setData([...formattedData, ...seriesRef.current?.data() ?? []]);
                loadedRangeRef.current = [data[0][0] / 1000, loadedRangeRef.current[1]];
                volumeSeriesRef.current?.setData([...volumeData, ...volumeSeriesRef.current?.data() ?? []]);
            } else {
                // Начальная загрузка
                seriesRef.current?.setData(formattedData);
                loadedRangeRef.current = [data[0][0] / 1000, data[data.length - 1][0] / 1000];
                volumeSeriesRef.current?.setData(volumeData);
            }

            const zigzagPoints = await getPivots(symbol, seriesRef.current?.data());

            const zigzagCharPoints = zigzagPoints.data.lines.map((item: any) => ({
                time: item.time,
                value: item.price,
                color: 'blue'
            }));

            let markers: SeriesMarker<Time>[] = zigzagPoints.data.points.map((item: any) => ({
                time: item.time as UTCTimestamp,
                position: !item.isHigh ? 'belowBar': 'aboveBar' as SeriesMarkerPosition,
                color: item.isHigh ? 'green' : 'red',
                shape: !item.isHigh ? 'arrowUp': 'arrowDown' as SeriesMarkerShape,
            }));


            seriesRef.current!.setMarkers(markers);

            zigzagSeriesRef.current?.setData(zigzagCharPoints);

        } catch (error) {
            console.error('Ошибка загрузки данных:', error);
        } finally {
            loadingRef.current = false;
        }
    };

    const handleIntervalChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newInterval = event.target.value;
        setChartInterval(newInterval); // Обновляем локальный интервал
    };

    // Функция для подключения к WebSocket Binance
    useEffect(() => {
        const ws = new WebSocket(`wss://fstream.binance.com/ws/${symbol.toLowerCase()}@kline_${chartInterval}`);

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data).k;

            // Извлекаем данные из сообщения
            const open = parseFloat(data.o); // Цена открытия
            const close = parseFloat(data.c); // Цена закрытия
            const high = parseFloat(data.h); // Максимальная цена
            const low = parseFloat(data.l); // Минимальная цена
            const volume = parseFloat(data.v); // Объем


            let time: UTCTimestamp = (parseFloat(data.t) / 1000) as UTCTimestamp;

            // Формируем объект свечи
            const newCandle: CandlestickData = {
                time, // Время открытия в секундах
                open,
                high,
                low,
                close,
            };

            const volumeCandle = {
                time: time,
                value: volume,
                color: close >= open ? '#26a69a' : '#ef5350', // Зелёный или красный
            }


            if (seriesRef.current) {
                // Получаем текущие данные
                const currentData = seriesRef.current.data();

                // Если данные есть, обновляем последнюю свечу
                if (currentData.length > 0) {
                    const lastCandle = currentData[currentData.length - 1];
                    // Если время текущей свечи совпадает с временем последней свечи, обновляем её
                    if (lastCandle.time === newCandle.time) {
                        seriesRef.current.update(newCandle);
                        volumeSeriesRef.current?.update(volumeCandle);
                    } else {
                        // Если это новая свеча, добавляем её
                        seriesRef.current.update(newCandle);
                        volumeSeriesRef.current?.update(volumeCandle);
                    }
                } else {
                    // Если данных нет, добавляем первую свечу
                    seriesRef.current.setData([...currentData, ...[newCandle]]);
                }
            }
        }

        // Очищаем WebSocket при размонтировании компонента
        return () => {
            ws.close();
        };
    }, [symbol]);

    return (
        <>
            <div>
                <label htmlFor="interval">Интервал:</label>
                <select id="interval" value={chartInterval} onChange={handleIntervalChange}>
                    <option value="1m">1 минута</option>
                    <option value="5m">5 минут</option>
                    <option value="15m">15 минут</option>
                    <option value="30m">30 минут</option>
                    <option value="1h">1 час</option>
                    <option value="4h">4 часа</option>
                    <option value="1d">1 день</option>
                </select>
            </div>
            <div
                ref={chartContainerRef}
                style={{position: 'relative', width: '100%', height: '100%'}}
            ></div>
        </>
    );
};

export default TradingChart;
