import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import ATRConfigForm from './Form/ATRConfigForm';
import MACDConfigForm from './Form/MACDConfigForm';
import {intervals, Simulation} from "../../SimulationView/SimulationView";
import api from "../../Api";

// другие компоненты

interface IndicatorFormProps {
    indicator: any | null;
    simulationId: string,
    type: string;
    onSubmit: (indicatorData: any) => void;
}

const IndicatorForm: React.FC<IndicatorFormProps> = ({ indicator, onSubmit, type, simulationId }) => {
    const {addIndicator, updateIndicator} = api.useGetSimulation();
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        interval: '1',
        config: {}
    });

    useEffect(() => {
        if (indicator) {
            setFormData({
                id: indicator.id,
                name: indicator.name,
                interval: indicator.interval,
                config: indicator.config || {}
            });
        }
    }, [indicator]);

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleConfigChange = (newConfig: any) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            config: newConfig
        }));
    };

    const handleCancel = () => {
        indicator = null;
    };

    const handleSubmit = (e: React.FormEvent) => {
        if(formData.id) {
            // update
        } else {
            let data:any = {};
            data.type = type;
            data.config = formData.config;
            data.interval = formData.interval;
            data.name = formData.name;


            addIndicator(simulationId, data).then(r => {console.log(r)});
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({...prevData, [name]: value}));
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="indicatorName">
                <Form.Label column={"sm"}>Название</Form.Label>
                <Form.Control
                    type="string"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="indicatorInterval">
                <Form.Label column={"sm"}>Интервал</Form.Label>
                <Form.Select
                    name="interval"
                    value={formData.interval}
                    onChange={handleSelect}

                >
                    {intervals.map((interval:string) => (
                        <option value={interval} key={interval}>
                            {interval}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>

            {type === 'ATR' && (
                <ATRConfigForm config={formData.config} onConfigChange={handleConfigChange} />
            )}
            {type === 'MACD' && (
                <MACDConfigForm config={formData.config} onConfigChange={handleConfigChange} />
            )}

            <Button variant="primary" onClick={handleSubmit} className={"add-indicator"}>
                {indicator ? "Сохранить изменения" : "Добавить индикатор"}
            </Button>
        </Form>
    );
};

export default IndicatorForm;