import React, {useEffect, useState} from 'react';
import Layout from './..//Layout';
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/ru';
import api from "../Api";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

moment.locale('ru');

const SettingsPage: React.FC = () => {
    const {getSettings, setSetting} = api.useSettings();
    const [formData, setFormData] = useState({
        parserEnabled: false,
        openInterestWindow: 15,
        openInterestMultiplier: 3,
        coinLog: '',
        pivotDeviationThreshold: '',
        pivotDepth: '',
    });
    const [baseData, setBaseData] = useState({
        parserEnabled: false,
        openInterestWindow: 15,
        openInterestMultiplier: 15,
        coinLog: '',
        pivotDeviationThreshold: '',
        pivotDepth: '',
    });
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        let response = await getSettings();

        updateSettings(response);
        setLoading(false);
    };

    const updateSettings = (response: any) => {
        setFormData({
            parserEnabled: response.data.settings?.parserEnabled ?? false,
            openInterestWindow: response.data.settings?.openInterestWindow ?? 15,
            openInterestMultiplier: response.data.settings?.openInterestMultiplier ?? 3,
            coinLog: response.data.settings?.coinLog ?? '',
            pivotDeviationThreshold: response.data.settings?.pivotDeviationThreshold ?? '',
            pivotDepth: response.data.settings?.pivotDepth ?? '',
        });

        setBaseData({
            parserEnabled: response.data.settings?.parserEnabled ?? false,
            openInterestWindow: response.data.settings?.openInterestWindow ?? 15,
            openInterestMultiplier: response.data.settings?.openInterestMultiplier ?? 3,
            coinLog: response.data.settings?.coinLog ?? '',
            pivotDeviationThreshold: response.data.settings?.pivotDeviationThreshold ?? '',
            pivotDepth: response.data.settings?.pivotDepth ?? '',
        });
    }

    const handleParserEnabled = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = e.target; // Получаем свойство checked из переключателя
        setFormData((prevData) => ({
            ...prevData,
            parserEnabled: checked, // Обновляем только поле parserEnabled
        }));

        updateSettings(await setSetting('parserEnabled', checked))
    };

    const handleNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value as unknown as number;
        let name = e.target.name;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleSave = async (field: keyof typeof formData) => {
        updateSettings(await setSetting(field, formData[field]));
    }

    if (loading) {
        return <Layout><LoadingScreen/></Layout>;
    }


    return (
        <Layout>
            <Form>
                <Form.Switch
                    name="parserEnabled"
                    checked={formData.parserEnabled as boolean}
                    label="Сбор статистики включён"
                    onChange={handleParserEnabled}
                />
                <Row>
                    <Col lg={3}>
                        <Form.Group>
                            <Form.Label column={"sm"}>Окно открытого интереса для анализа</Form.Label>
                            <InputGroup className="mb-3" size={"sm"}>
                                <Form.Control
                                    type="number"
                                    name="openInterestWindow"
                                    value={formData.openInterestWindow}
                                    onChange={handleNumberInput}
                                />
                                <Button
                                    variant={formData.openInterestWindow == baseData.openInterestWindow ? 'outline-secondary' : 'outline-success'}
                                    disabled={formData.openInterestWindow == baseData.openInterestWindow}
                                    onClick={() => handleSave('openInterestWindow')}>
                                    <Button color={'green'}/>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col lg={3}>
                        <Form.Group>
                            <Form.Label column={"sm"}>Лог монеты</Form.Label>
                            <InputGroup className="mb-3" size={"sm"}>
                                <Form.Control
                                    type="text"
                                    name="coinLog"
                                    value={formData.coinLog}
                                    onChange={handleNumberInput}
                                />
                                <Button
                                    variant={formData.coinLog == baseData.coinLog ? 'outline-secondary' : 'outline-success'}
                                    disabled={formData.coinLog == baseData.coinLog}
                                    onClick={() => handleSave('coinLog')}>
                                    <Button color={'green'}/>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col lg={3}>
                        <Form.Group>
                            <Form.Label column={"sm"}>Мультипликатор аномального открытого интереса</Form.Label>
                            <InputGroup className="mb-3" size={"sm"}>
                                <Form.Control
                                    type="number"
                                    name="openInterestMultiplier"
                                    value={formData.openInterestMultiplier}
                                    onChange={handleNumberInput}
                                />
                                <Button
                                    variant={formData.openInterestMultiplier == baseData.openInterestMultiplier ? 'outline-secondary' : 'outline-success'}
                                    disabled={formData.openInterestMultiplier == baseData.openInterestMultiplier}
                                    onClick={() => handleSave('openInterestMultiplier')}>
                                    <Button color={'green'}/>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col lg={3}>
                        <Form.Group>
                            <Form.Label column={"sm"}>Тресхолд пивотов</Form.Label>
                            <InputGroup className="mb-3" size={"sm"}>
                                <Form.Control
                                    type="number"
                                    name="pivotDeviationThreshold"
                                    value={formData.pivotDeviationThreshold}
                                    onChange={handleNumberInput}
                                />
                                <Button
                                    variant={formData.pivotDeviationThreshold == baseData.pivotDeviationThreshold ? 'outline-secondary' : 'outline-success'}
                                    disabled={formData.pivotDeviationThreshold == baseData.pivotDeviationThreshold}
                                    onClick={() => handleSave('pivotDeviationThreshold')}>
                                    <Button color={'green'}/>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label column={"sm"}>Глубина пивотов</Form.Label>
                            <InputGroup className="mb-3" size={"sm"}>
                                <Form.Control
                                    type="number"
                                    name="pivotDepth"
                                    value={formData.pivotDepth}
                                    onChange={handleNumberInput}
                                />
                                <Button
                                    variant={formData.pivotDepth == baseData.pivotDepth ? 'outline-secondary' : 'outline-success'}
                                    disabled={formData.pivotDepth == baseData.pivotDepth}
                                    onClick={() => handleSave('pivotDepth')}>
                                    <Button color={'green'}/>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>

            </Form>
        </Layout>
    );
};

export default SettingsPage;
