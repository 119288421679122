import {useRequest} from './RequestContext';
import {Moment} from "moment/moment";

const api = {
    useLogin: () => {
        const {request} = useRequest();
        const login = (email: string, password: string) => request('/auth/login', 'POST', {email, password});
        return {login};
    },
    useCreateSimulation: () => {
        const {request} = useRequest();
        const createSimulation = (data: object) => request('/simulation', 'POST', data);
        return {createSimulation};
    },
    useGetQuotations: () => {
        const {request} = useRequest();
        const getQuotations = (from: number, to: number, interval: string, symbol: string) => request('/quotation', 'GET', null, {
            from: from,
            to: to,
            interval: interval,
            symbol: symbol,
        });
        return {getQuotations};
    },
    useGetSimulations: () => {
        const {request} = useRequest();
        const getSimulations = () => request('/simulation', 'GET');
        return {getSimulations};
    },
    useSettings: () => {
        const {request} = useRequest();
        const getSettings = () => request('/settings', 'GET');
        const setSetting = (key: string, value: any) => request('/settings', 'PUT', {
            key: key,
            value: value
        });
        return {getSettings, setSetting};
    },
    useCoins: () => {
        const {request} = useRequest();
        const getCoins = () => request('/coin', 'GET');
        const addLabel = (symbol:string, label:string) => request(`/coin/${symbol}/label/${label}`, 'POST');
        const getPivots = (symbol:string, data:any) => request(`/coin/${symbol}/pivots`, 'POST', {data:data});
        const deleteLabel = (symbol:string, label:string) => request(`/coin/${symbol}/label/${label}`, 'DELETE');
        const deleteCoin = (symbol:string) => request(`/coin/${symbol}`, 'DELETE');
        const syncCoins = () => request('/coin/sync', 'GET');
        return {getCoins, syncCoins, deleteCoin, addLabel, deleteLabel, getPivots};
    },
    useFLush: () => {
        const {request} = useRequest();
        const flush = () => request('/flush', 'POST');
        return {flush};
    },
    useRestartSimulation: () => {
        const {request} = useRequest();
        const restartSimulation = (id: string, data: object) => request(`/simulation/${id}/restart`, 'POST', data);
        return {restartSimulation};
    },
    useGetSimulation: () => {
        const {request} = useRequest();
        const getSimulation = (id: string) => request(`/simulation/${id}`, 'GET');
        const getTriggers = (id: string, params: object | null) => request(`/simulation/${id}/trigger`, 'GET', null, params);
        const getOrders = (id: string) => request(`/simulation/${id}/order`, 'GET');
        const getIndicators = (id: string) => request(`/simulation/${id}/indicator`, 'GET');
        const addIndicator = (id: string, data: any) => request(`/simulation/${id}/indicator`, 'POST', data);
        const updateIndicator = (id: string, indicatorId: string, data: any) => request(`/simulation/${id}/indicator/${indicatorId}`, 'PUT');
        const removeIndicator = (id: string, indicatorId: string) => request(`/simulation/${id}/indicator/${indicatorId}`, 'DELETE');
        const deleteSimulation = (id: string) => request(`/simulation/${id}`, 'DELETE');
        return {
            getSimulation,
            getTriggers,
            deleteSimulation,
            getOrders,
            getIndicators,
            addIndicator,
            updateIndicator,
            removeIndicator
        };
    },
}

export default api;