import React from 'react';
import { Form } from 'react-bootstrap';

interface ATRConfigFormProps {
    config: any;
    onConfigChange: (newConfig: any) => void;
}

const ATRConfigForm: React.FC<ATRConfigFormProps> = ({ config, onConfigChange }) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onConfigChange({
            ...config,
            [name]: value
        });
    };

    return (
        <div>
            <Form.Group controlId="atrPeriod">
                <Form.Label column={"sm"}>Длина</Form.Label>
                <Form.Control
                    type="number"
                    name="length"
                    value={config.length || ''}
                    onChange={handleInputChange}
                />
            </Form.Group>
        </div>
    );
};

export default ATRConfigForm;
