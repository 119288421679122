import React, {useEffect, useState} from 'react';
import './CoinList.css';
import Layout from './../../Layout';
import api from '../../Api/api';
import {Badge, Button, Col, Form, ListGroup, Modal, Row, Table} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

export interface Coin {
    symbol: string;
    pricePrecision: number;
    coinPrecision: number;
    labels: string[];
    priceChange: {
        '1m': number;
        '5m': number;
        '1d': number;
    }
}

const labelColors: Record<string, string> = {
    favorite: 'success',
    top10: 'info',
    ethFamily: 'light',
    blackList: 'dark',
    handled: 'dark',
};

const availableLabels = ['favorite', 'top10', 'ethFamily', 'blackList', 'handled'];

const CoinList: React.FC = () => {
    const [coins, setCoins] = useState<Coin[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const {getCoins, syncCoins, deleteCoin, addLabel, deleteLabel} = api.useCoins();
    const [selectedCoin, setSelectedCoin] = useState<Coin | null>(null);
    const [selectedFilterLabels, setSelectedFilterLabels] = useState<string[]>([]); // Фильтр по меткам
    const [searchQuery, setSearchQuery] = useState(''); // Фильтр по названию

    const toggleLabelFilter = (label: string) => {
        setSelectedFilterLabels((prev) =>
            prev.includes(label) ? prev.filter((l) => l !== label) : [...prev, label]
        );
    };

    const toggleLabel = async (label: string) => {
        if (!selectedCoin) return;

        const isRemoving = selectedCoin.labels.includes(label);

        if (isRemoving) {
            await deleteLabel(selectedCoin.symbol, label);
        } else {
            await addLabel(selectedCoin.symbol, label);
        }

        const updatedLabels = selectedCoin.labels.includes(label)
            ? selectedCoin.labels.filter((l) => l !== label)
            : [...selectedCoin.labels, label];

        const updatedCoins = coins.map((coin) =>
            coin.symbol === selectedCoin.symbol ? {...coin, labels: updatedLabels} : coin
        );

        setCoins(updatedCoins);
        setSelectedCoin({...selectedCoin, labels: updatedLabels});
    };

    useEffect(() => {
        fetchCoins();
    }, []);

    const fetchCoins = async () => {
        try {
            const response = await getCoins();

            const coins = response.data.coins.map((item: any) => ({
                symbol: item.symbol,
                pricePrecision: item.pricePrecision,
                coinPrecision: item.coinPrecision,
                labels: item.labels,
                priceChange: item.priceChange
            }));

            setCoins(coins);
        } catch (err: any) {
            setError('Failed to fetch coins');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Layout><LoadingScreen/></Layout>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Фильтрация монет на основе значения фильтра
    const filteredCoins = coins.filter((coin) => {
        const matchesQuery = coin.symbol.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesLabels =
            selectedFilterLabels.length === 0 ||
            selectedFilterLabels.every((label) => coin.labels.includes(label));
        return matchesQuery && matchesLabels;
    });

    const handleSyncCoins = async () => {
        try {
            await syncCoins();
            await fetchCoins();
        } catch (err) {
            console.error('Failed to delete coin', err);
        }
    }

    const handleDeleteCoin = async (symbol: string) => {
        if (window.confirm("Уверен?")) {
            await deleteCoin(symbol);
            await getCoins();
        }
    }

    function handleGoToCoin(symbol: string) {
        navigate('/coin/' + symbol);
    }

    return (
        <Layout>
            <Row className={"header-row"}>
                <Col lg={"6"}>
                    <Form.Control
                        type="text"
                        placeholder="Search by coin symbol"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="mb-2"
                    />
                </Col>
                <Col lg={"5"}>
                    {availableLabels.map((label) => (
                        <Badge
                            key={label}
                            color={"red"}
                            bg={selectedFilterLabels.includes(label) ? labelColors[label] : labelColors[label] + 'light'}
                            onClick={() => toggleLabelFilter(label)}
                            style={{cursor: 'pointer', marginRight: '5px'}}
                        >
                            {label}
                        </Badge>
                    ))}
                </Col>
                <Col lg={1}>
                    <Button variant={"primary"} onClick={()=> handleSyncCoins()}>SYNC</Button>
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover className={"coin-list"}>
                    <thead>
                    <tr>
                        <th>Монета</th>
                        <th>1min change</th>
                        <th>5min change</th>
                        <th>1day change</th>
                        <th></th>
                        <th>Метки</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredCoins.map((coin) => (
                        <tr key={coin.symbol}>
                            <td>
                                <a onClick={() => handleGoToCoin(coin.symbol)}>{coin.symbol}</a>
                            </td>
                            <td>
                                <Badge bg={coin.priceChange['1m'] > 0 ? 'success': 'danger'}>{coin.priceChange['1m']}%</Badge>
                            </td>
                            <td>
                                <Badge bg={coin.priceChange['5m'] > 0 ? 'success': 'danger'}>{coin.priceChange['5m']}%</Badge>
                            </td>
                            <td>
                                <Badge bg={coin.priceChange['1d'] > 0 ? 'success': 'danger'}>{coin.priceChange['1d']}%</Badge>
                            </td>
                            <td>
                                <Link className={"btn btn-sm btn-primary external-link"} to={"https://www.coinglass.com/tv/ru/Binance_" + coin.symbol} target={"_blank"} >CG</Link>
                                <Link className={"btn btn-sm btn-primary"} to={"https://ru.tradingview.com/chart/IVVTn7Hl/?symbol=BINANCE%3A" + encodeURIComponent(coin.symbol)} target={"_blank"} >TV</Link>
                            </td>
                            <td>
                                {coin.labels.map((label) => (
                                    <Badge key={label} bg={labelColors[label] || 'secondary'}
                                           className={"coin-label"}>{label}</Badge>
                                ))}
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => setSelectedCoin(coin)}
                                >
                                    Edit
                                </Button>
                            </td>
                            <td>
                                <Button size={"sm"} variant={"danger"}
                                        onClick={() => handleDeleteCoin(coin.symbol)}>Удалить</Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Row>
            {/* Modal for Editing Labels */}
            {selectedCoin && (
                <Modal
                    show={!!selectedCoin}
                    onHide={() => setSelectedCoin(null)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Labels for {selectedCoin.symbol}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup>
                            {availableLabels.map((label) => (
                                <ListGroup.Item
                                    key={label}
                                    active={selectedCoin.labels.includes(label)}
                                    onClick={() => toggleLabel(label)}
                                    style={{cursor: 'pointer'}}
                                >
                                    {label}
                                    {selectedCoin.labels.includes(label) && (
                                        <Badge bg="success" className="ms-2">
                                            Added
                                        </Badge>
                                    )}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setSelectedCoin(null)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Layout>
    );
};

export default CoinList;
