import React from 'react';
import { Form } from 'react-bootstrap';

interface MACDConfigFormProps {
    config: any;
    onConfigChange: (newConfig: any) => void;
}

const MACDConfigForm: React.FC<MACDConfigFormProps> = ({ config, onConfigChange }) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onConfigChange({
            ...config,
            [name]: value
        });
    };

    return (
        <div>
            <Form.Group controlId="macdShortPeriod">
                <Form.Label column={"sm"}>Короткий период</Form.Label>
                <Form.Control
                    type="number"
                    name="shortPeriod"
                    value={config.shortPeriod || ''}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group controlId="macdLongPeriod">
                <Form.Label column={"sm"}>Длинный период</Form.Label>
                <Form.Control
                    type="number"
                    name="longPeriod"
                    value={config.longPeriod || ''}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group controlId="macdSignalPeriod">
                <Form.Label column={"sm"}>Сигнальный период</Form.Label>
                <Form.Control
                    type="number"
                    name="signalPeriod"
                    value={config.signalPeriod || ''}
                    onChange={handleInputChange}
                />
            </Form.Group>
        </div>
    );
};

export default MACDConfigForm;
