import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Layout from './../../Layout';
import {Button, Col, Form, Row} from 'react-bootstrap';
import api from '../../Api/api';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import {Moment} from "moment/moment";
import 'moment/locale/ru';
import './CreateSimulation.css'
import './datepicker.css';
import {Coin} from "../../Coin/List/CoinList";

moment.locale('ru');

const Login: React.FC = () => {
    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        symbol: '',
    });
    const [errors, setErrors] = useState<{
        startDate?: string;
        endDate?: string;
        symbol?: string;
    }>({});
    const navigate = useNavigate();
    const {createSimulation} = api.useCreateSimulation();
    const {getCoins} = api.useCoins();
    const [coins, setCoins] = useState<Coin[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchCoins();
    }, []);

    const fetchCoins = async () => {
        try {
            const response = await getCoins();

            const coins = response.data.coins.map((item: any) => ({
                symbol: item.symbol,
                pricePrecision: item.pricePrecision,
                coinPrecision: item.coinPrecision
            }));

            setCoins(coins);
        } catch (err: any) {
            setError('Failed to fetch coins');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({...prevData, [name]: value}));
        if (errors[name as keyof typeof errors]) {
            setErrors((prevErrors) => ({...prevErrors, [name]: undefined}));
        }
    };

    const handleStartDate = (value: Moment) => {
        setFormData({
            ...formData,
            ['startDate']: value.toISOString()
        });
        if (errors.startDate) {
            setErrors((prevErrors) => ({...prevErrors, startDate: undefined}));
        }
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        setFormData((prevData) => ({...prevData, [name]: checked}));
    };

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const handleEndDate = (value: Moment) => {
        setFormData({
            ...formData,
            ['endDate']: value.toISOString()
        });
        if (errors.endDate) {
            setErrors((prevErrors) => ({...prevErrors, endDate: undefined}));
        }
    };

    const handleCreate = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await createSimulation(formData);
            navigate('/simulation');
        } catch (error: any) {
            if (error && error.status === 400 && error.data && error.data.errors) {
                setErrors(error.data.errors);
            } else {
                console.error(error.data.message);
            }
        }
    };

    return (
        <Layout>
            <Form onSubmit={handleCreate}>
                <Row>
                    <Col lg={2}>
                        <h5>Даты симуляций</h5>
                        <Form.Label column={"sm"}>начало</Form.Label>
                        <Datetime
                            dateFormat="DD MMMM YYYY"
                            timeFormat="HH:mm:ss"
                            utc={true}
                            locale="ru"
                            value={formData.startDate ? moment(formData.startDate) : ''}
                            onChange={handleStartDate}
                            inputProps={{className: errors.startDate ? 'form-control is-invalid' : 'form-control'}}
                        />
                        <Form.Label column={"sm"}>конец</Form.Label>
                        <Datetime
                            utc={true}
                            dateFormat="DD MMMM YYYY"
                            timeFormat="HH:mm:ss"
                            locale="ru"
                            value={formData.endDate ? moment(formData.endDate) : ''}
                            onChange={handleEndDate}
                            inputProps={{className: errors.endDate ? 'form-control is-invalid' : 'form-control'}}
                        />
                        <Form.Label column={"sm"}>Монета</Form.Label>
                        <Form.Select value={formData.symbol} name="symbol" onChange={handleSelect}>
                            <option value="">--SELECT--</option>
                            {coins.map((coin) => (
                                <option value={coin.symbol} key={coin.symbol}>
                                    {coin.symbol}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="success" type="submit">
                            Создать
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default Login;
